<template>
  <div class="antideceive">
    <div class="container py-5">
      <div class="bg-primary p-4 p-md-5">
        <div class="d-flex">
          <h2 class="text-white fw-normal fs-1 ms-5 mb-4">反詐騙</h2>
          <span class="d-block fs-2 text-white">重要提醒</span>
        </div>
        <h5 class="text-white lh-lg fw-normal">感謝您對 Papawash購物網的支持，近日詐騙集團猖獗，詐騙手法日新月異，Papawash購物網不會以帳務異常、分期付款設定錯誤、要求 ATM 轉帳或退款…等名義與您聯繫，若您接獲類似上述通知，請無須理會，並撥打165反詐騙專線詢問或於 Papawash 購物網的會員中心
            <router-link to="/contactus" class="fw-bold text-white">聯絡我們</router-link>
            線上提問，我們將於上班時間內盡速與您聯繫。
        </h5>
      </div>
      <div class="d-flex justify-content-start">
        <h5 class="text-center fw-normal my-4">Papawash 購物網特別提出以下注意項目，提醒您切勿受騙：</h5>
      </div>
      <ul class="list-unstyled">
        <li class="fs-6"><i class="bi bi-diamond-fill me-1"></i>本公司不會於<span class="text-primary">非上班時間</span>以電話或簡訊與您確認訂單內容或核對相關資料。</li>
        <li class="fs-6 mt-2"><i class="bi bi-diamond-fill me-1"></i>本公司不會以重複訂購、刷卡錯誤與您聯繫，或要求您依指示轉帳、操作ATM。</li>
        <li class="fs-6 mt-2"><i class="bi bi-diamond-fill me-1"></i>詐騙集團常以竄改來電顯示號碼的方式假冒金融機構，若接獲可疑電話或簡訊，切勿回撥電話或回傳簡訊。</li>
      </ul>
      <div class="text-center d-md-flex justify-content-center mt-5 mb-2">
        <p class="fs-6 pe-3">Papawash 購物網上班時間</p>
        <p class="fs-6 border-start-md border-end-md border-secondary border-4 px-3">週一~週五</p>
        <p class="fs-6 ps-3">9:00~12:00、13:30~18:00</p>
      </div>
      <p class="text-center fs-6 mb-4">若您有任何問題，請於上班時間撥打客服專線0809-080-038或至會員中心
          <router-link to="/contactus" class="fw-bold">聯絡我們</router-link>
          提問求證。
      </p>
      <div class="d-flex justify-content-center">
        <div class="circle"></div>
        <div class="circle mx-3"></div>
        <div class="circle"></div>
      </div>
      <p class="text-primary fs-2 text-center mt-4">保持冷靜 提高警覺 小心查證</p>
    </div>
  </div>
</template>

<script>

export default {
}
</script>

<style scoped>
.title{
  text-indent: 4%;
}
.circle{
  background: #003894;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
